<template>
  <Layout>
    <PageHeader :items="items"/>
    <div class="alertMsg">
      <b-alert v-model="showOrderAlert" dismissible variant="success">
        {{ alertMsg }}
      </b-alert>
    </div>
    <!--FILTER START------>
    <div
        v-if="
        this.$storageData.profile.login_type == 2 ||
          this.$storageData.profile.empTypeID == 2 ||
          this.$storageData.profile.empTypeID == 11
      "
        class="card"
        style="margin-top: -30px; margin-bottom: 3.25rem"
    >
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2" style="width: 12%">
            <label>Select Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getRestaurantCities()"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- city List-->
          <div
              v-if="
              this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
              class="col-md-2"
              style="width: 16%"
          >
            <label>Select City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="readRestaurantBranchData()"
            ></multiselect>
          </div>
          <!-- city List END-->
          <!-- Brand List-->
          <div
              v-if="
              this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 11 ||
                this.$storageData.profile.empTypeID == 9
            "
              class="col-md-2"
              style="width: 16%"
          >
            <label>Select Restaurant Brand</label>
            <multiselect
                v-model="brandData"
                :options="brandArr"
                :show-labels="false"
                label="name"
                track-by="restID"
                @input="readRestaurantBranchData()"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Branch List-->
          <div
              v-if="
              this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
              class="col-md-2"
              style="width: 16%"
          >
            <label>Select Restaurant Branch </label>
            <multiselect
                v-model="branchID"
                :options="branches"
                :show-labels="false"
                label="branchName"
                track-by="restBranchID"
                @input="getOrdersList(0, 1)"
            ></multiselect>
          </div>
          <!-- Branch List END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>
    <head>
      <meta charset="UTF-8"/>
      <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
      <meta content="ie=edge" http-equiv="X-UA-Compatible"/>
    </head>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        {{ notificationText }}
      </b-alert>
    </div>

    <!--  1=>Initiated, 2=>Pending, 3=> Accepted,4=>Preparing,5=>Prepared,6=>Dispatched,7=>Delivered,8=>Cancelled    -->
    <div
        class="button-items"
        v-bind:style="
        this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 11
          ? 'margin-bottom: 10px;margin-top: 5px;'
          : 'margin-bottom: 10px;margin-top: -18px;'
      "
    >
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 0 }"
          @click="getOrdersList(0, 1)"
      >
        All
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 2 }"
          @click="getOrdersList(2, 1)"
      >
        Pending
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 3 }"
          @click="getOrdersList(3, 1)"
      >
        Accepted
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 4 }"
          @click="getOrdersList(4, 1)"
      >
        Preparing
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 5 }"
          @click="getOrdersList(5, 1)"
      >
        Prepared
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 6 }"
          @click="getOrdersList(6, 1)"
      >
        Dispatched
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 7 }"
          @click="getOrdersList(7, 1)"
      >
        Delivered
      </button>
      <button
          class="btn btn-outline-secondary text-uppercase"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 8 }"
          @click="getOrdersList(8, 1)"
      >
        Cancelled
      </button>

      <button
          :disabled="isReloadDisabled"
          class="btn btn-themeOrange"
          style="position: absolute; right: 135px; padding: 6px; font-size: 13px"
          @click="getOrdersList(0, 1), disableReload()"
      >
        Reload <i class="uil uil-refresh"></i>
      </button>
      <button
          :disabled="isReloadDisabled"
          class="btn btn-outline-secondary text-uppercase"
          style="position: absolute; right: 14px"
          type="button"
          v-bind:class="{ buttonClicked: orderStatusValue == 9 }"
          @click="getOrdersList(9), disableReload()"
      >
        Future Orders
        <b-badge v-if="futureOrdersCount > 0" variant="light">{{
            futureOrdersCount
          }}
        </b-badge>
      </button>
    </div>

    <div class="text-center">
      <b-spinner
          v-if="orderLoader"
          class="m-2 col-3"
          role="status"
          variant="primary"
      ></b-spinner>
    </div>

    <div class="search-wrapper" style="margin-bottom: 10px">
      <label>Search</label>
      <input v-model="search" placeholder="Search.." type="text"/>
    </div>

    <div
        v-if="
        orderStatusValue == 0 && orderData.length == 0 && orderLoader == false
      "
        class="placeholderDiv"
    >
      <img src="@/assets/images/empty-cart.png"/>
      <h6>Ready For Order</h6>
      <p>
        You're open and available for new orders. As soon as you receive an
        order, you'll see it here
      </p>
    </div>

    <div class="row">
      <div
          v-for="(item, index) in filteredList"
          :key="index"
          class="col-xl-3 col-sm-6"
      >
        <!-- {{ item.delTime }} -->
        <div class="card">
          <!-- v-bind:style="item.isExpired == 1 ? 'background: #eee;' : 'background: #fff;'" -->
          <div class="card-body">
            <b-card-title class="row" style="margin-bottom: -4px">
              <h6
                  class="col-md-6"
                  style="padding-left: 0px; padding-right: 0px"
              >
                <span style="color: #f3766a">{{ item.orderID }}</span>
              </h6>
              <vue-countdown
                  v-if="counting && item.orderStatus == 3"
                  v-slot="{ totalMinutes }"
                  :time="getCountdownTime(item.avgPreparationTime)"
                  class="col-md-6 blink_me"
                  style="
                  padding-left: 0px;
                  padding-right: 0px;
                  text-align: right;
                  color: green;
                  font-size: 13px;
                  font-weight: 500;
                "
                  @end="onCountdownEnd"
              >{{ totalMinutes }} mins left
              </vue-countdown
              >
            </b-card-title>
            <div class="row mb-2">
              <span class="col-md-12">
                {{ item.orderType }} | {{ item.created }}
              </span>
            </div>

            <!-- <div class="orderText">
              <i class="fas fa-location-arrow iconClass"></i>
              <span>{{ item.restBranchName }}</span>
            </div> -->

            <div class="orderText">
              <i class="fas fa-money-bill iconClass"></i>
              <span>₹ {{ item.totalSum }}</span>
              <span class="paymentTag">{{
                  item.paymentType ? item.paymentType : item.paymentMode
                }}</span>
            </div>
            <br/>
            <!-- <div class="orderText">
                <i class="fas fa-home iconClass"></i>
                <span>{{ item.address }}</span>
            </div> -->

            <div class="orderText">
              <i class="fas fa-user iconClass"></i>
              <span>{{ item.userName }}</span>
            </div>
            <br/>
            <!-- <div class="orderText">
                <i class="fas fa-mobile-alt iconClass"></i>
                <span>{{ item.mobileNo }}</span>
            </div> -->

            <div v-if="item.orderStatus == 2" class="col-md-12 mb-2">
              <multiselect
                  v-model="deliveryPartner"
                  :options="deliveryPartnerArr"
                  :show-labels="false"
                  label="partnerName"
                  placeholder="Select Delivery Partner*"
                  track-by="partnerID"
              ></multiselect>
            </div>
            <div v-else class="orderText">
              <i class="fas fa-truck iconClass"></i>
              <span>Restaurant Delivery</span>
            </div>

            <div class="row mb-2">
              <span
                  v-if="
                  futureOrders == 1 ||
                    (item.delTime > currentTime() &&
                      item.orderType == 'Schedule')
                "
                  class="badge font-size-12 bg-soft-danger"
                  style="width: 100%; margin-bottom: 10px; border-radius: 0; color: #212121;"
              >
                Scheduled for {{ item.delDateTime }}
              </span>
            </div>

            <div class="row">
              <div
                  class="col-md-6"
                  style="width: fit-content; padding-right: 0;"
              >
                <i
                    class="far fa-eye viewIcons"
                    style="margin-right: 5px"
                    @click="viewOrderDetails(item.id)"
                ></i>
                <i
                    v-if="item.orderStatus != 8 && item.orderStatus != 7"
                    class="uil-multiply cancelIcon"
                    @click="openCancelModal(item.id)"
                ></i>
              </div>
              <div
                  class="col-md-6"
                  style="width: fit-content; padding-left: 0; margin-left: auto;"
              >
                <b-button
                    v-if="item.orderStatus != 8 && item.orderStatus != 7"
                    :disabled="isBtnDisabled"
                    href="javascript:void(0);"
                    style="
                    float: right;
                    width: auto;
                    padding: 0px 4px;
                    min-height: 38px;
                    line-height: 38px;
                    font-size: 13px;
                  "
                    variant="themeYellow"
                    @click="
                    changeOrderStatus(
                      item.orderStatus,
                      item.id,
                      deliveryPartner
                    )
                  "
                >{{ item.markButtonStatus }}
                </b-button
                >
              </div>
              <!-- <div class="col-md-3">
                <i
                  v-if="item.orderStatus != 8 && item.orderStatus != 7"
                  class="uil-multiply cancelIcon"
                  @click="openCancelModal(item.id)"
                ></i>
              </div> -->
              <p
                  v-if="item.orderStatus == 8"
                  class="col-md-6"
                  style="color: red; font-size: 15px; margin: 5px 0px 0px 0px"
              >
                Order Cancelled
              </p>
              <p
                  v-if="item.orderStatus == 7"
                  class="col-md-6"
                  style="color: #41980a; font-size: 15px; margin: 5px 0px 0px 0px"
              >
                Order Delivered
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept
        :orderDetails="this.orderDetails"
        :orderStatusArr="this.orderStatusArr"
        :orderedItemArr="this.orderedItemArr"
        :invoice="this.invoice"
    >
    </OrderReciept>

    <!-- Cancel order modal -->
    <b-modal id="modal-cancelOrder" title="Reject Order" title-class="font-18">
      <div>
        <b-form-radio
            ref="aaaa"
            v-model="cancelMsg"
            class="mb-3"
            plain
            value="1"
        >Item out of stock.
        </b-form-radio
        >
        <b-form-radio v-model="cancelMsg" class="mb-3" plain value="2"
        >Quality of ordered item not up to standards.
        </b-form-radio
        >
        <b-form-radio v-model="cancelMsg" class="mb-3" plain value="3"
        >Customer asked to cancel.
        </b-form-radio
        >
        <b-form-radio v-model="cancelMsg" class="mb-3" plain value="4"
        >Item not priced correctly.
        </b-form-radio
        >
        <b-form-radio v-model="cancelMsg" class="mb-3" plain value="5"
        >Other
        </b-form-radio
        >

        <div v-show="cancelMsg === '5'">
          <textarea
              v-model="cancelOtherReason"
              class="form-control"
              name="textarea"
              rows="3"
          ></textarea>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              class="float-right"
              size="sm"
              variant="primary"
              @click="cancelOrder()"
          >
            Cancel Order
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueCountdown from "@chenfengyuan/vue-countdown";
import OrderReciept from "@/components/orderReciept";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Orders",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {Layout, PageHeader, VueCountdown, Multiselect, OrderReciept},
  data() {
    return {
      alertMsg: "New order received",
      showOrderAlert: false,
      title: "Orders",
      search: "",
      items: [
        {
          text: "Order List"
        },
        {
          text: "Orders",
          active: true
        }
      ],
      counting: true,
      restaurants: [],
      restaurantID: [],
      orderData: [],
      orderDetails: [],
      invoice: [],
      futureOrdersCount: 0,
      orderStatusArr: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      clicked: false,
      isRestLogin: this.$storageData.profile.restBranchID,
      displayOrderStatusBtn: "Mark Accepted",
      orderStatusValue: 0,
      showDismissibleAlert: false,
      notificationText: "",
      futureOrders: 0,
      orderLoader: false,
      cancelOrderID: "",
      cancelMsg: "1",
      isBtnDisabled: false,
      isReloadDisabled: false,
      cancelReason: "",
      cancelOtherReason: "",
      deliveryPartnerArr: [
        {partnerID: 1, partnerName: "Delivery by Restaurant"}
      ],
      //deliveryPartner:[ {"partnerID":1,"partnerName":"Delivery by Restaurant"}],
      deliveryPartner: "",
      brandArr: [],
      brandData: "",
      city: "",
      cityArr: [],
      countryArr: [],
      country: "",
      branchID: "",
      branches: [],
      interval: null,
    };
  },
  computed: {
    filteredList() {
      return this.orderData.filter(order => {
        return (
            order.orderID.toLowerCase().includes(this.search.toLowerCase()) ||
            order.userName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
  watch: {
    orderData() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.getOrdersList(0, 1);
      }, 30000);
    },
  },
  methods: {
    async print() {
      //window.print();
      var divToPrint = document.getElementById("reciept");

      var newWin = window.open("", "Print-Window");

      newWin.document.open();

      newWin.document.write(
          '<html><body onload="window.print()">' +
          divToPrint.innerHTML +
          "</body></html>"
      );

      newWin.document.close();

      setTimeout(function () {
        newWin.close();
      }, 10);
    },
    currentDate() {
      const current = new Date();
      var h = current.getHours(),
          m = current.getMinutes();
      var thistime = h > 12 ? h - 12 + ":" + m : h + ":" + m;
      const date = `${current.getFullYear()}-${current.getMonth() +
      1}-${current.getDate()} ${thistime}`;
      return date;
    },
    currentTime() {
      const current = new Date();
      const time = `${current.getHours()}:${current.getMinutes() + 1}`;
      return time;
    },
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    getCountdownTime(time) {
      //alert(time);
      if (time == 0) {
        time = 60;
      }
      return time * 60 * 1000;
    },

    getRestaurantList() {
      this.axios.get(this.$loggedRole + "/getRestaurantList").then(result => {
        this.restaurants = result.data.data;
      });
    },
    disableReload() {
      this.isReloadDisabled = true;
      setTimeout(() => (this.isReloadDisabled = false), 3000);
    },
    getOrdersList(orderStatus, flag) {
      this.disableReload();
      //  alert(orderStatus);
      this.futureOrders = 0;
      this.orderLoader = true;
      if (flag == 1) {
        this.orderStatusValue = orderStatus;
      }

      if (orderStatus == 9) {
        // future orders list
        this.futureOrders = 1;
      }
      var restBranchID = this.branchID.restBranchID;
      if (this.$storageData.profile.restBranchID != 0) {
        restBranchID = this.$storageData.profile.restBranchID;
      }
      if (
          restBranchID == "" ||
          restBranchID == undefined ||
          restBranchID == 0 ||
          restBranchID.length <= 0
      ) {
        this.$swal({
          icon: "error",
          title: "Manage Orders",
          text: "Please select restaurant branch."
        });
      } else {
        this.axios
            .post(this.$loggedRole + "/getOrders", {
              restBranchID: restBranchID,
              orderStatus: orderStatus,
              futureOrders: this.futureOrders,
              clusterID: this.$storageData.profile.clusterID
            })
            .then(result => {
              this.orderLoader = false;
              this.orderData = result.data.data;
              this.futureOrdersCount = result.data.futureOrdersCount;
              // if(result.data.data){
              //
              // }

            })
            .catch(error => {
              this.$swal({
                icon: "error",
                title: "Manage Orders",
                text: error.response.data.message
              });
            });
      }
    },

    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
          .post(this.$loggedRole + "/getOrderDetailsByID", {orderID: orderID})
          .then(result => {
            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
            this.invoice = result.data.invoice;
            //  console.log(result.data.data.orderedItemArr);
            this.orderedItemArr = result.data.data.orderedItemArr;
            // this.orderItemAddonArr = result.data.orderItemAddonArr;
          });
    },
    changeOrderStatus(status, orderID, deliveryPartner) {
      //alert(this.deliveryPartner.partnerID);
      //alert(deliveryPartner);
      this.isBtnDisabled = true;

      if (deliveryPartner == undefined && status == 2) {
        this.$swal({
          icon: "error",
          title: "Manage Orders",
          text: "Please select delivery partner."
        });
        this.isBtnDisabled = false;
        return;
        //
      }
      this.axios
          .post(this.$loggedRole + "/changeOrderStatus", {
            orderStatus: status,
            empID: this.$storageData.profile.pid,
            orderID: orderID,
            deliveryPartner: deliveryPartner ? deliveryPartner.partnerID : 0
          })
          .then(() => {
            this.isBtnDisabled = false;

            this.getOrdersList(this.orderStatusValue);
            this.showDismissibleAlert = true;
            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);

            this.getNotificationText(status);
          })
          .catch(error => {
            this.isBtnDisabled = false;
            this.$swal({
              icon: "error",
              title: "Manage Orders",
              text: error.response.data.message
            });

          });
    },
    getNotificationText(orderStatus) {
      // <!-- 1=>Initiated, 2=>Pending/placed, 3=> Accepted,4=>Preparing,5=>Prepared,6=>Dispatched,7=>Delivered,8=>Cancelled   -->
      ///  below are the next order status written////
      switch (orderStatus) {
        case 2:
          this.notificationText = "Order marked as Accepted";
          break;
        case 3:
          this.notificationText = "Order marked as Preparing";
          break;
        case 4:
          this.notificationText = "Order marked as Prepared";
          break;

        case 5:
          this.notificationText = "Order marked as Dispatched";
          break;

        case 6:
          this.notificationText = "Order marked as Delivered";
          break;

        default:
          this.notificationText = "Order updated";
      }
    },
    openCancelModal(orderID) {
      //this.$bvModal.show(id)
      this.cancelMsg = 1;
      (this.cancelOtherReason = ""),
          this.$root.$emit("bv::show::modal", "modal-cancelOrder");
      this.cancelOrderID = orderID;
    },

    cancelOrder() {
      this.$root.$emit("bv::hide::modal", "modal-cancelOrder");
      switch (this.cancelMsg) {
        case "1":
          this.cancelReason = "Quality of ordered item not up to standards.";
          break;
        case "2":
          this.cancelReason = "Item out of stock.";
          break;
        case "3":
          this.cancelReason = "Quality of ordered item not up to standards.";
          break;
        case "4":
          this.cancelReason = "Item not priced correctly.";
          break;
        case "5":
          this.cancelReason = this.cancelOtherReason;
          break;
      }

      this.axios
          .post(this.$loggedRole + "/changeOrderStatus", {
            orderStatus: 8,
            cancelReason: this.cancelReason,
            empID: this.$storageData.profile.pid,
            orderID: this.cancelOrderID
          })
          .then(() => {
            this.getOrdersList(status);
            this.showDismissibleAlert = true;
            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);

            this.getNotificationText(status);
            this.initRefund(this.cancelOrderID);
          });
    },
    initRefund(orderID) {
      let getRefundDetails = JSON.stringify({
        orderID: orderID,
        empID: this.$storageData.profile.pid,
        loginType: this.$storageData.login_type
      });
      this.axios
          .post(this.$loggedRole + "/refundOrder", getRefundDetails)
          .then(response => {
            // console.log(response.data.cuisineDetails);
            let status = response.status;
            if (status == 200 && response.data.status == 200) {
              return;
            }
          })
          .catch(error => {
            console.log(error.response.data.message);
          });
      // this.$storageData.login_type this.$storageData.profile.pid this.cancelOrderID
    },

    getRestaurantCountry() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantCountry", {restID: this.$storageData.profile.restID})
          .then(response => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getRestaurantCities();
          });
    },
    getRestaurantCities() {
      // if (this.$storageData.profile.city != "") {
      //   this.cityArr = [{ city: this.$storageData.profile.city }];
      //   this.city = this.cityArr ? this.cityArr[0] : "";
      //   this.getRestaurantBrands();
      // } else {
      //   this.axios
      //     .post(this.$loggedRole+"/getRestaurantCities", {
      //       restID:
      //         this.$storageData.login_type == 1
      //           ? this.restaurantID.restID
      //           : this.$storageData.profile.restID,
      //       country: this.country ? this.country.country : "",
      //     })
      //     .then((response) => {
      //       this.cityArr = response.data.data;
      //       this.city = this.cityArr ? this.cityArr[0] : "";
      //       if (this.$storageData.profile.empTypeID == 11) {
      //         this.getRestaurantBrandsByCompanyID();
      //       } else {
      //         this.readRestaurantBranchData();
      //       }
      //     });
      // }

      this.axios
          .post(this.$loggedRole + "/getRestaurantCities", {
            restID:
                this.$storageData.login_type == 1
                    ? this.restaurantID.restID
                    : this.$storageData.profile.restID,
            country: this.country ? this.country.country : ""
          })
          .then(response => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 11
            ) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
    },
    getRestaurantBrandsByCompanyID() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantBrandsByCompanyID", {
            companyID: this.$storageData.profile.companyID,
            restID:
                this.$storageData.login_type == 2 &&
                this.$storageData.profile.empTypeID != 11
                    ? this.$storageData.profile.restID
                    : 0,
            restBranchID:
                this.$storageData.login_type == 2 &&
                this.$storageData.profile.empTypeID != 11
                    ? this.$storageData.profile.restBranchID
                    : 0
          })
          .then(response => {
            this.brandArr = response.data.data;
            this.brandData = this.brandArr ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
          });
    },

    readRestaurantBranchData() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantBranchByRestID", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID: 0,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : ""
          })
          .then(response => {
            this.branches = response.data.data;
            this.branchID = this.branches ? this.branches[0] : "";
            this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
            this.getOrdersList(0);
          });
    },
    getRestaurantBrands() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantList", {
            restID: this.$storageData.profile.restID,
            restBranchID: this.$storageData.profile.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.$storageData.profile.city
          })
          .then(result => {
            this.brandArr = result.data.data;
            this.readRestaurantBranchData();
          });
    }
  },

  mounted() {
    // alert(this.deliveryPartner.partnerID);
    // alert(this.isRestLogin);
    //this.getRestaurantList();
    // var restBranchID = this.branchID.restBranchID;
    // if (this.$storageData.profile.restBranchID != 0) {
    //   restBranchID = this.$storageData.profile.restBranchID;
    // }
    if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    } else if (this.$storageData.profile.empTypeID == 9) {
      this.getRestaurantCities();
    } else {
      this.getOrdersList(0);
    }
    this.getOrdersList(0, 1);
    this.deliveryPartner = this.deliveryPartnerArr[0];

  },
  middleware: "authentication"
};
</script>

<style>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

.orderText {
  display: inline-block;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 5px;
}

.iconClass {
  margin-right: 18px;
  font-size: 17px;
  color: grey;
}

.paymentTag {
  background: #064406;
  padding: 3px 4px;
  color: white;
  margin-left: 15px;
  font-size: 11px;
}

.viewIcons {
  border: 1px solid #f37667;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  color: #f37667;
}

.cancelIcon {
  border: 1px solid grey;
  padding: 6px 10px;
  font-size: 17px;
  border-radius: 5px;
  color: grey;
}

.nav-tabs .nav-link.active {
  background-color: #f5f6f8 !important;
}

.col-xl-3 {
  max-width: 300px !important;
}

.activity-feed .feed-item {
  padding-bottom: 2px;
}

.buttonClicked {
  background-color: #74788d;
  color: white;
}

.badge {
  background-color: #f3766a !important;
  color: white;
}

.placeholderDiv {
  text-align: center;
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 283px;
  height: 100px;
  margin: auto;
  background-color: #f3f3f3;
}

.dot1 {
  height: 8px;
  width: 8px;
  background-color: #a4827d;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px;
}

.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
<style>
.btn-outline-secondary {
  padding: 6px;
  font-size: 13px;
}

/* Dashed red border */
hr.new2 {
  border-top: 1px dashed grey;
}

#reciept {
  box-shadow: 0px 0px 9px rgb(15 34 58 / 12%);
  padding: 13px;
  max-width: 360px;
  font-size: 14px;
}

.amountTypeSpan {
  min-width: 108px;
  margin-left: 44%;
}
</style>
